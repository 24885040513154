document.querySelectorAll('.expert__read-more-button').forEach((element) => {
    element.addEventListener('click', () => {
        const elmId = element.dataset.id;
        const qa = document.querySelectorAll(`.expert__details[data-id=${elmId}]`);
        qa[0].classList.toggle('expert__details-open');
        if (qa[0].classList.contains('expert__details-open')) {
            if (element.classList.contains('reviewer-button')) {
                element.innerHTML = 'Collapse Details';
            } else {
                element.innerHTML = 'Collapse Answers';
            }
        } else {
            element.innerHTML = 'Read More';
            element.parentElement.scrollIntoView();
        }
    });
});

const reviewers__container = document.getElementById('reviewers__container');

if (reviewers__container) {
    if (reviewers__container.childElementCount >= 5) {
        const experts = reviewers__container.children;
        for (let i = 5; i < experts.length; i += 1) {
            experts[i].classList.add('d-none');
            experts[i].classList.add('can-collapse-true');
        }
        const expandable_children = document.querySelectorAll('.can-collapse-true');
        const viewAllBtn = document.getElementById('reviewers--view-all');
        viewAllBtn.classList.remove('d-none');

        viewAllBtn.addEventListener('click', () => {
            for (const expert of expandable_children) {
                expert.classList.toggle('d-none');
            }
            if (viewAllBtn.innerText.includes('Show')) {
                viewAllBtn.innerText = 'Collapse Reviewers [-]';
            } else {
                viewAllBtn.innerText = 'Collapse Reviewers [-]';
                viewAllBtn.innerText = 'Show All Reviewers [+]';
            }
            viewAllBtn.classList.remove('d-none');
        });
    }
}
